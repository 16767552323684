<template>
  <div v-if="invoice" :key="'invoice_' + invoiceUpdateCounter">
    <modal-edit-invoice-footer
      v-if="showModalEditFooter"
      :showModal="showModalEditFooter"
      :onClickCancel="hideModal"
    />

    <modal-edit-invoice-address
      v-if="showModalEditAddress"
      :showModal="showModalEditAddress"
      :onClickCancel="hideModal"
    />

    <modal-edit-invoice-detail
      v-if="showModalEditDetail"
      :showModal="showModalEditDetail"
      :onClickCancel="hideModal"
    />

    <modal-send-invoice
      v-if="showModalSendInvoice"
      :showModal="showModalSendInvoice"
      :onClickCancel="hideModal"
      @dataSaved="invoiceSend"
    />

    <div
      class="actionBar has-background-white-ter has-padding has-margin-bottom"
    >
      <div class="level">
        <div class="level-left"></div>
        <div class="level-right">
          <is-pinned
            class="has-icon"
            :locationId="locationId"
            :type="'invoice'"
            :itemId="invoice.Id"
          ></is-pinned>
          <a @click="downloadInvoice" class="has-icon">
            <span class="icon is-small">
              <font-awesome-icon :icon="['fas', 'file-download']" />
            </span>
            <span>{{
              $t('Components.Invoices.InvoiceDetail.Icon_Download')
            }}</span>
          </a>

          <a @click="setShowModalSendInvoice" class="has-icon">
            <span class="icon is-small">
              <font-awesome-icon :icon="['fas', 'paper-plane']" />
            </span>
            <span>Send mail</span>
          </a>
        </div>
      </div>
    </div>

    <accept-reject v-if="invoice.StatusId === 0" />

    <div class="navbar-menu">
      <div class="navbar-menu">
        <div class="navbar-start"></div>

        <div class="navbar-end">
          <div class="navbar-item">
            <div class="field is-grouped">
              <p class="control">
                <span
                  v-if="isInitiated && invoice.StatusId === 0 && !canAuthorize"
                  class="notification is-warning"
                  >{{
                    $t(
                      'Components.Invoices.InvoiceDetail.Navbar_You-cant-authorize'
                    )
                  }}</span
                >

                <a
                  v-if="isInitiated && invoice.StatusId === 1"
                  @click="creditInvoice"
                  class="has-padding-left has-padding-right"
                >
                  <span class="icon">
                    <font-awesome-icon :icon="['fas', 'file-invoice']" />
                  </span>
                  <span>{{
                    $t('Components.Invoices.InvoiceDetail.Icon_Credit')
                  }}</span>
                </a>

                <a
                  v-if="
                    isInitiated &&
                      invoice.StatusId === 1 &&
                      invoice.AmountOpen > 0
                  "
                  @click="markInvoiceAsPaid"
                  class="has-padding-left"
                >
                  <span class="icon">
                    <font-awesome-icon :icon="['fas', 'coins']" />
                  </span>
                  <span>{{
                    $t('Components.Invoices.InvoiceDetail.Icon_Mark-as-paid')
                  }}</span>
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <section class="section">
      <div class="container">
        <div class="columns">
          <div class="column has-text-right">
            <ui-section-header
              :showEdit="invoice.StatusId === 0"
              :editFunction="setShowModalEditAddress"
            />

            <table class="table is-fullwidth is-narrow">
              <thead>
                <tr>
                  <th>
                    {{ $t('Components.Invoices.InvoiceDetail.Table_Address') }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-if="invoice.CompanyId > 0">
                  <td>
                    {{ invoice.CompanyName }}
                  </td>
                </tr>
                <tr v-if="invoice.SendToName !== ''">
                  <td>
                    {{ invoice.SendToName }}
                  </td>
                </tr>
                <tr v-if="invoice.PoNumber !== ''">
                  <td>
                    {{ invoice.PoNumber }}
                  </td>
                </tr>
                <tr v-if="invoice.VatNumber !== ''">
                  <td>
                    {{ invoice.VatNumber }}
                  </td>
                </tr>
                <tr v-if="invoice.Address !== ''">
                  <td>
                    {{ invoice.Address }}
                  </td>
                </tr>
                <tr v-if="invoice.PostalCode !== ''">
                  <td>
                    {{ invoice.PostalCode }}
                  </td>
                </tr>
                <tr v-if="invoice.City !== ''">
                  <td>
                    {{ invoice.City }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="column has-text-right">
            <ui-section-header
              :showEdit="invoice.StatusId === 0"
              :editFunction="setShowModalEditDetail"
            />

            <table class="table is-fullwidth is-narrow">
              <thead>
                <tr>
                  <th colspan="2">
                    {{ $t('Components.Invoices.InvoiceDetail.Table_Details') }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td width="100">
                    {{
                      $t('Components.Invoices.InvoiceDetail.Table_Invoice-nr')
                    }}
                  </td>
                  <td class="has-text-right">
                    <span v-if="invoice.StatusId !== 0">{{
                      invoice.Code
                    }}</span>
                    <span v-if="invoice.StatusId === 0">{{
                      $t('Components.Invoices.InvoiceDetail.Table_Concept')
                    }}</span>
                  </td>
                </tr>
                <tr>
                  <td width="100">
                    {{
                      $t('Components.Invoices.InvoiceDetail.Table_Reference')
                    }}
                  </td>
                  <td class="has-text-right">{{ invoice.Reference }}</td>
                </tr>
                <!-- <tr>
                <td width="100">Debtor</td>
                <td class="has-text-right">{{ invoice.DebtorId }}</td>
              </tr> -->
                <tr>
                  <td width="100">
                    {{
                      $t('Components.Invoices.InvoiceDetail.Table_Issue-date')
                    }}
                  </td>
                  <td class="has-text-right">
                    <span v-if="extractYear(invoice.ActivationDate) !== 1900">{{
                      invoice.ActivationDate
                        | parseIsoDateStringToDate
                        | dateFormat('en', 'longDateFormat')
                    }}</span>
                    <span v-if="extractYear(invoice.ActivationDate) === 1900">{{
                      new Date() | dateFormat('en', 'longDateFormat')
                    }}</span>
                  </td>
                </tr>
                <tr>
                  <td width="100">
                    {{ $t('Components.Invoices.InvoiceDetail.Table_Due-date') }}
                  </td>
                  <td class="has-text-right">
                    {{
                      invoice.ExpirationDate
                        | parseIsoDateStringToDate
                        | dateFormat('en', 'longDateFormat')
                    }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </section>

    <div v-for="reservationId in invoice.ReservationIds" :key="reservationId">
      <section class="section is-small">
        <div class="container">
          <p v-if="invoice.IsCollective" class="subtitle">
            #{{ reservationId }}
          </p>
          <table class="table is-fullwidth">
            <thead>
              <tr>
                <th>{{ $t('Components.Invoices.InvoiceDetail.Table_Qty') }}</th>
                <th>
                  {{
                    $t('Components.Invoices.InvoiceDetail.Table_Description')
                  }}
                </th>
                <th width="150" class="has-text-right">
                  {{ $t('Components.Invoices.InvoiceDetail.Table_Price') }}
                </th>
                <th width="100" class="has-text-right">
                  {{ $t('Components.Invoices.InvoiceDetail.Table_Tax') }}
                </th>
                <th width="100" class="has-text-right">
                  {{ $t('Components.Invoices.InvoiceDetail.Table_Total') }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="space in getSpaceRows(reservationId)" :key="space.Id">
                <td width="100">
                  <span v-if="!space.IsTextRow">{{ space.Amount }}x</span>
                </td>
                <td>{{ space.Text }}</td>
                <td width="150" class="has-text-right">
                  <span v-if="!space.IsTextRow">{{
                    space.PricePerItem | toCurrency('nl-NL', 'EUR')
                  }}</span>
                </td>
                <td width="100" class="has-text-right">
                  <span v-if="!space.IsTextRow"
                    >{{ space.TaxPercentage }}%</span
                  >
                </td>
                <td class="has-text-right" width="100">
                  <span v-if="!space.IsTextRow">{{
                    space.TotalExclTax | toCurrency('nl-NL', 'EUR')
                  }}</span>
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <td colspan="4">
                  {{ $t('Components.Invoices.InvoiceDetail.Table_Subtotal') }}
                </td>
                <td class="has-text-right">
                  {{
                    getSpaceSubTotal(reservationId) | toCurrency('nl-NL', 'EUR')
                  }}
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
      </section>

      <section class="section is-small">
        <div class="container" style="align:right">
          <table
            class="table is-fullwidth"
            v-if="getOptionRows(reservationId).length > 0"
          >
            <thead v-if="!invoice.IsCollective">
              <tr>
                <th>{{ $t('Components.Invoices.InvoiceDetail.Table_Qty') }}</th>
                <th>
                  {{
                    $t('Components.Invoices.InvoiceDetail.Table_Description')
                  }}
                </th>
                <th width="150" class="has-text-right">
                  {{ $t('Components.Invoices.InvoiceDetail.Table_Price') }}
                </th>
                <th width="100" class="has-text-right">
                  {{ $t('Components.Invoices.InvoiceDetail.Table_Tax') }}
                </th>
                <th class="has-text-right" width="100">
                  {{ $t('Components.Invoices.InvoiceDetail.Table_Total') }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="option in getOptionRows(reservationId)"
                :key="option.Id"
              >
                <td width="100">{{ option.Amount }}x</td>
                <td>{{ option.Text }}</td>
                <td width="150" class="has-text-right">
                  {{ option.PricePerItem | toCurrency('nl-NL', 'EUR') }}
                </td>
                <td width="100" class="has-text-right">
                  {{ option.TaxPercentage }}%
                </td>
                <td class="has-text-right" width="100">
                  {{ option.TotalExclTax | toCurrency('nl-NL', 'EUR') }}
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <td colspan="4">
                  {{ $t('Components.Invoices.InvoiceDetail.Table_Subtotal') }}
                </td>
                <td class="has-text-right">
                  {{
                    getOptionSubTotal(reservationId)
                      | toCurrency('nl-NL', 'EUR')
                  }}
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
      </section>
    </div>

    <section class="is-clearfix section is-small">
      <div class="container">
        <table class="table is-narrow is-pulled-right">
          <tbody>
            <tr>
              <td width="250">
                {{
                  $t('Components.Invoices.InvoiceDetail.Table_Total-excl-tax')
                }}
              </td>
              <td class="has-text-right" width="100">
                {{ invoice.TotalExclTax | toCurrency }}
              </td>
            </tr>
            <tr v-for="tax in taxRows" :key="tax.Id">
              <td width="250">
                {{ $t('Components.Invoices.InvoiceDetail.Table_Total-tax') }}
                {{ tax.TaxPercentage }}%
              </td>
              <td class="has-text-right" width="100">
                {{ tax.TotalExclTax | toCurrency('nl-NL', 'EUR') }}
              </td>
            </tr>
          </tbody>
          <tfoot>
            <tr class="has-text-weight-bold">
              <td width="150">
                {{
                  $t('Components.Invoices.InvoiceDetail.Table_Total-incl-tax')
                }}
              </td>
              <td class="has-text-right" width="100">
                {{ invoice.TotalInclTax | toCurrency }}
              </td>
            </tr>
            <tr v-if="invoice.TotalPaid > 0" class="is-italic">
              <td>
                {{ $t('Components.Invoices.InvoiceDetail.Table_Total-paid') }}
              </td>
              <td class="has-text-right">
                {{ invoice.TotalPaid | toCurrency }}
              </td>
            </tr>

            <tr v-if="invoice.AmountOpen > 0" class="has-text-weight-bold">
              <td width="150">
                {{ $t('Components.Invoices.InvoiceDetail.Table_Amount-open') }}
              </td>
              <td class="has-text-right" width="100">
                {{ invoice.AmountOpen | toCurrency }}
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
    </section>

    <section class="section is-size-7">
      <div class="container">
        <ui-section-header
          :showEdit="invoice.StatusId === 0"
          :editFunction="setShowModalEditFooter"
        >
        </ui-section-header>

        <message
          v-if="invoice.Footer.length === 0"
          :message="'No footer found in this invoice'"
        />

        <div
          v-if="invoice.Footer.length > 0"
          v-html="invoice.Footer"
          class="actionBar has-background-white-ter has-padding has-margin-bottom"
        ></div>
      </div>
    </section>
  </div>
</template>

<script>
import axios from 'axios'
import { EventBus } from '@/eventbus/event-bus'
import { mapGetters, mapState, mapMutations } from 'vuex'
import invoiceProvider from '@/providers/invoice'
import paymentProvider from '@/providers/payment'

const InvoiceAcceptReject = () =>
  import('@/components/Invoices/InvoiceAcceptReject')
const Message = () => import('@/components/UI/Message')
const ModalEditInvoiceAddress = () =>
  import('@/components/Invoices/ModalEditInvoiceAddress')
const ModalEditInvoiceDetail = () =>
  import('@/components/Invoices/ModalEditInvoiceDetail')
const ModalEditInvoiceFooter = () =>
  import('@/components/Invoices/ModalEditInvoiceFooter')
const ModalSendInvoice = () => import('@/components/Invoices/ModalSendInvoice')
const IsPinned = () => import('@/components/Pins/IsPinned')

export default {
  components: {
    'is-pinned': IsPinned,
    'accept-reject': InvoiceAcceptReject,
    message: Message,
    'modal-edit-invoice-footer': ModalEditInvoiceFooter,
    'modal-edit-invoice-address': ModalEditInvoiceAddress,
    'modal-edit-invoice-detail': ModalEditInvoiceDetail,
    'modal-send-invoice': ModalSendInvoice,
  },

  props: {
    invoiceId: {
      default: 0,
      type: Number,
    },
  },

  data() {
    return {
      invoiceUpdateCounter: 0,
      invoiceSettings: null,
      locationId: Number(this.$route.params.locationId),
      showModalEditAddress: false,
      showModalEditDetail: false,
      showModalEditFooter: false,
      showModalSendInvoice: false,
      isInitiated: false,
    }
  },

  computed: {
    ...mapState('invoiceStore', ['invoice']),

    ...mapGetters({
      profile: 'getProfile',
    }),

    canAuthorize() {
      let self = this
      let canAuthorize = false

      if (self.invoiceSettings && self.invoice) {
        if (
          self.invoiceSettings.AllowSelfAuthorization ||
          self.invoice.CreatedBy !== self.profile.Id
        ) {
          canAuthorize = true
        }
      }

      return canAuthorize
    },

    taxRows() {
      let self = this
      let rows = []

      if (
        self.invoice &&
        self.invoice.InvoiceLines &&
        self.invoice.InvoiceLines.length > 0
      ) {
        rows = self.invoice.InvoiceLines.filter((l) => l.TextGroupId === 4)
      }

      return rows
    },
  },

  created() {
    this.getInvoiceSettings()
  },

  methods: {
    ...mapMutations('invoiceStore', ['setInvoice']),

    decodeString(txt) {
      return this.$stringHelper.decodeHtml(txt)
    },

    getInvoiceSettings() {
      invoiceProvider.methods
        .getLocationInvoiceSettings(this.locationId)
        .then((response) => {
          if (response.status === 200) {
            this.invoiceSettings = response.data
            this.isInitiated = true
          }
        })
    },

    getSpaceRows(reservationId) {
      let self = this
      let rows = []

      if (
        self.invoice &&
        self.invoice.InvoiceLines &&
        self.invoice.InvoiceLines.length > 0
      ) {
        rows = self.invoice.InvoiceLines.filter(
          (l) => l.ReservationId === reservationId && l.TextGroupId === 1
        )
      }

      return rows
    },

    getSpaceSubTotal(reservationId) {
      let self = this
      let total = 0
      let spaceRows = this.getSpaceRows(reservationId)

      for (let i = 0; i < spaceRows.length; i++) {
        total = total + spaceRows[i].TotalExclTax
      }

      return total
    },

    /* 
    Get all the option invoice lines from a reservation
    */
    getOptionRows(reservationId) {
      let self = this
      let rows = []

      if (
        self.invoice &&
        self.invoice.InvoiceLines &&
        self.invoice.InvoiceLines.length > 0
      ) {
        rows = self.invoice.InvoiceLines.filter(
          (l) => l.ReservationId === reservationId && l.TextGroupId === 2
        )
      }

      return rows
    },

    /* 
    Calculate on per reservation base the total of the selected options
    */
    getOptionSubTotal(reservationId) {
      let self = this
      let total = 0
      let optionRows = this.getOptionRows(reservationId)

      for (let i = 0; i < optionRows.length; i++) {
        total = total + optionRows[i].TotalExclTax
      }

      return total
    },

    creditInvoice() {
      invoiceProvider.methods
        .creditInvoice(this.invoice.Id)
        .then((response) => {
          if (response.status === 200) {
            this.setInvoice(response.data)

            EventBus.$emit('showToast', {
              type: 'info',
              message: 'Invoice is credited!',
            })
          }
        })
    },

    setShowModalEditAddress() {
      this.showModalEditAddress = true
    },

    setShowModalEditDetail() {
      this.showModalEditDetail = true
    },

    setShowModalEditFooter() {
      this.showModalEditFooter = true
    },

    setShowModalSendInvoice() {
      this.showModalSendInvoice = true
    },

    hideModal() {
      this.showModalEditAddress = false
      this.showModalEditDetail = false
      this.showModalEditFooter = false
      this.showModalSendInvoice = false
    },

    markInvoiceAsPaid() {
      paymentProvider.methods
        .addPayment(this.invoice.ReservationId, 0, this.invoice.AmountOpen)
        .then((response) => {
          if (response.status === 201) {
            this.invoice.TotalPaid = this.invoice.AmountOpen
            this.invoice.AmountOpen = 0
            this.$store.dispatch('fillVersionStoreDataSets', this.invoice)
          }
        })
    },

    async downloadInvoice() {
      invoiceProvider.methods.getInvoicePdf(this.invoiceId).then((response) => {
        const linkSource = `data:application/pdf;base64,${response.data}`
        const pdfBlob = this.dataURItoBlob(linkSource)

        /**
         * Internet Explorer stuff!
         */
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(pdfBlob, `${Date.now()}.pdf`)
          return
        }

        const url = window.URL.createObjectURL(pdfBlob)
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `${Date.now()}.pdf`)
        document.body.appendChild(link)
        link.click()

        link.remove()
        return response
      })
    },

    dataURItoBlob(dataURI) {
      const byteString = atob(dataURI.split(',')[1])
      const mimeString = dataURI
        .split(',')[0]
        .split(':')[1]
        .split(';')[0]

      // write the bytes of the string to an ArrayBuffer
      const ab = new ArrayBuffer(byteString.length)
      const ia = new Uint8Array(ab)
      for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i)
      }

      // write the ArrayBuffer to a blob, and you're done
      const bb = new Blob([ab], { type: mimeString })
      return bb
    },

    extractYear(dateString) {
      let year = 0
      let date = this.returnDateObject(dateString)
      if (date) {
        year = date.getFullYear()
      }

      return year
    },

    returnDateObject(dateString) {
      return this.$options.filters.parseIsoDateStringToDate(dateString)
    },
  },
}
</script>
